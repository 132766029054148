// To add to list add it in the add method and if you have Typescript set up properly it should auto-import
// Then sort imports and copy them back over to add, this eliminates doubles.
// Try to add only to regular, solid and light are being deprecated for now

import {
  faAlignLeft,
  faAngleDown,
  faAngleRight,
  faArchive,
  faArrowUp,
  faArrowDown,
  faArrowsUpDown,
  faArrowLeft,
  faArrowRight,
  faArrowsAltV,
  faArrowUpRightFromSquare,
  faBaby,
  faBalanceScale,
  faBars,
  faBell,
  faBinoculars,
  faBriefcase,
  faBriefcaseMedical,
  faBuilding,
  faBullseyeArrow,
  faBullseyePointer,
  faCalendar,
  faCalendarAlt,
  faCalendarRange,
  faCar,
  faCaretDown,
  faCaretUp,
  faChartBar,
  faChartLine,
  faCheck,
  faChevronLeft,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faChild,
  faCircle,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCirclePlus,
  faCircleQuestion,
  faCircleXmark,
  faClipboard,
  faClipboardListCheck,
  faClose,
  faCloudDownload,
  faCocktail,
  faCog,
  faCommentAlt,
  faCommentAltLines,
  faCommentsAlt,
  faCreditCardFront,
  faCross,
  faDatabase,
  faDonate,
  faDownload,
  faEdit,
  faEllipsis,
  faEnvelope,
  faEuroSign,
  faExchange,
  faExclamationCircle,
  faExclamationTriangle,
  faExpandAlt,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFeatherAlt,
  faFile,
  faFileAlt,
  faFileChartLine,
  faFileContract,
  faFileDownload,
  faFileLines,
  faFilePdf,
  faFileExcel,
  faFileExport,
  faFilePlus,
  faFileUpload,
  faFilter,
  faFlag,
  faFolderPlus,
  faFolders,
  faFolderTree,
  faGavel,
  faGear,
  faGem,
  faGift,
  faGlobe,
  faGlobeEurope,
  faGraduationCap,
  faHandHolding,
  faHandHoldingHand,
  faHandHoldingHeart,
  faHandHoldingSeedling,
  faHandHoldingUsd,
  faHandPointDown,
  faHandPointLeft,
  faHandPointUp,
  faHandshakeAlt,
  faHeart,
  faHeartCircle,
  faHome,
  faHouse,
  faIndustryAlt,
  faInfo,
  faInfoCircle,
  faInfoSquare,
  faIslandTropical,
  faKey,
  faLandmark,
  faLanguage,
  faLightbulb,
  faLineHeight,
  faLink,
  faList,
  faListCheck,
  faLocationCircle,
  faLock,
  faMagnifyingGlass,
  faMap,
  faMapMarkerAlt,
  faMapSigns,
  faMarker,
  faMars,
  faMegaphone,
  faMicrochipAi,
  faMinus,
  faMoneyCheck,
  faPalette,
  faPencil,
  faPencilAlt,
  faPenSquare,
  faPhone,
  faPieChart,
  faPiggyBank,
  faPlane,
  faPlayCircle,
  faPlug,
  faPlus,
  faPresentation,
  faPresentationScreen,
  faQuestion,
  faQuestionCircle,
  faRingsWedding,
  faRoute,
  faScaleBalanced,
  faSearch,
  faShareAlt,
  faShield,
  faShoppingCart,
  faSignOut,
  faSitemap,
  faSlidersUp,
  faSmile,
  faSpinner,
  faSquare,
  faSyncAlt,
  faTableCellsLarge,
  faTachometerAlt,
  faTags,
  faText,
  faTimes,
  faToggleOn,
  faTrash,
  faTrashAlt,
  faTreeAlt,
  faTrophy,
  faUmbrella,
  faUndo,
  faUniversity,
  faUpload,
  faUser,
  faUserCircle,
  faUserCog,
  faUserGraduate,
  faUserPlus,
  faUsers,
  faUsersClass,
  faUserShield,
  faUserSlash,
  faUserTie,
  faVenus,
  faWallet,
  faWrench,
  faXmark,
  faCloud,
  faSortAmountDown,
  faSortAmountUp,
  faPen,
  faWindowMaximize
} from "@fortawesome/pro-regular-svg-icons";

// Do not add to this list, try using regular
import {
  faAlignLeft as fasAlignLeft,
  faAngleRight as fasAngleRight,
  faArchive as fasArchive,
  faArrowLeft as fasArrowLeft,
  faArrowRight as fasArrowRight,
  faArrowUp as fasArrowUp,
  faArrowsAltV as fasArrowsAltV,
  faArrowUpRightFromSquare as fasArrowUpRightFromSquare,
  faAsterisk as fasAsterisk,
  faBaby as fasBaby,
  faBalanceScale as fasBalanceScale,
  faBars as fasBars,
  faBell as fasBell,
  faBinoculars as fasBinoculars,
  faBold as fasBold,
  faBriefcase as fasBriefcase,
  faBriefcaseMedical as fasBriefcaseMedical,
  faBuilding as fasBuilding,
  faBullseyeArrow as fasBullseyeArrow,
  faBullseyePointer as fasBullseyePointer,
  faCalendar as fasCalendar,
  faCalendarAlt as fasCalendarAlt,
  faCalendarRange as fasCalendarRange,
  faCar as fasCar,
  faCaretDown as fasCaretDown,
  faCaretRight as fasCaretRight,
  faCaretUp as fasCaretUp,
  faChartArea as fasChartArea,
  faChartBar as fasChartBar,
  faChartPie as fasChartPie,
  faChartLine as fasChartLine,
  faCheck as fasCheck,
  faCheckSquare as fasCheckSquare,
  faChevronDown as fasChevronDown,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faChevronDoubleRight as fasChevronDoubleRight,
  faChevronUp as fasChevronUp,
  faChild as fasChild,
  faCircle as fasCircle,
  faCircleCheck as fasCircleCheck,
  faCircleExclamation as fasCircleExclamation,
  faCircleInfo as fasCircleInfo,
  faCirclePlus as fasCirclePlus,
  faCircleQuestion as fasCircleQuestion,
  faCircleXmark as fasCircleXmark,
  faClipboard as fasClipboard,
  faClipboardListCheck as fasClipboardListCheck,
  faClose as fasClose,
  faCloudDownload as fasCloudDownload,
  faCocktail as fasCocktail,
  faCog as fasCog,
  faColumns as fasColumns,
  faCommentAlt as fasCommentAlt,
  faCommentAltLines as fasCommentAltLines,
  faCommentsAlt as fasCommentsAlt,
  faCreditCardFront as fasCreditCardFront,
  faCross as fasCross,
  faDatabase as fasDatabase,
  faDonate as fasDonate,
  faDownload as fasDownload,
  faEdit as fasEdit,
  faEllipsis as fasEllipsis,
  faEnvelope as fasEnvelope,
  faEuroSign as fasEuroSign,
  faExchange as fasExchange,
  faExchangeAlt as fasExchangeAlt,
  faExclamationCircle as fasExclamationCircle,
  faExclamationTriangle as fasExclamationTriangle,
  faExpandAlt as fasExpandAlt,
  faExternalLink as fasExternalLink,
  faEye as fasEye,
  faEyeSlash as fasEyeSlash,
  faFeatherAlt as fasFeatherAlt,
  faFile as fasFile,
  faFileAlt as fasFileAlt,
  faFileChartLine as fasFileChartLine,
  faFileContract as fasFileContract,
  faFileDownload as fasFileDownload,
  faFileExport as fasFileExport,
  faFileImport as fasFileImport,
  faFileLines as fasFileLines,
  faFilePdf as fasFilePdf,
  faFilePlus as fasFilePlus,
  faFileUpload as fasFileUpload,
  faFilter as fasFilter,
  faFlag as fasFlag,
  faFolderPlus as fasFolderPlus,
  faFolders as fasFolders,
  faFolderTree as fasFolderTree,
  faFont as fasFont,
  faGavel as fasGavel,
  faGear as fasGear,
  faGem as fasGem,
  faGift as fasGift,
  faGlobe as fasGlobe,
  faGlobeEurope as fasGlobeEurope,
  faGraduationCap as fasGraduationCap,
  faGripLines as fasGripLines,
  faGripLinesVertical as fasGripLinesVertical,
  faHandHolding as fasHandHolding,
  faHandHoldingHand as fasHandHoldingHand,
  faHandHoldingHeart as fasHandHoldingHeart,
  faHandHoldingSeedling as fasHandHoldingSeedling,
  faHandPointDown as fasHandPointDown,
  faHandPointLeft as fasHandPointLeft,
  faHandPointUp as fasHandPointUp,
  faHandshakeAlt as fasHandshakeAlt,
  faHeading as fasHeading,
  faHeart as fasHeart,
  faHeartCircle as fasHeartCircle,
  faHighlighter as fasHighlighter,
  faHome as fasHome,
  faHouse as fasHouse,
  faImage as fasImage,
  faIndustryAlt as fasIndustryAlt,
  faInfo as fasInfo,
  faInfoCircle as fasInfoCircle,
  faInfoSquare as fasInfoSquare,
  faIslandTropical as fasIslandTropical,
  faItalic as fasItalic,
  faKey as fasKey,
  faLandmark as fasLandmark,
  faLanguage as fasLanguage,
  faLightbulb as fasLightbulb,
  faLineHeight as fasLineHeight,
  faLink as fasLink,
  faList as fasList,
  faListCheck as fasListCheck,
  faListOl as fasListOl,
  faListUl as fasListUl,
  faLocationCircle as fasLocationCircle,
  faLock as fasLock,
  faLockOpen as fasLockOpen,
  faMagnifyingGlass as fasMagnifyingGlass,
  faMap as fasMap,
  faMapMarkerAlt as fasMapMarkerAlt,
  faMapSigns as fasMapSigns,
  faMarker as fasMarker,
  faMars as fasMars,
  faMegaphone as fasMegaphone,
  faMinus as fasMinus,
  faMinusCircle as fasMinusCircle,
  faMoneyCheck as fasMoneyCheck,
  faPageBreak as fasPageBreak,
  faPalette as fasPalette,
  faPaperclip as fasPaperclip,
  faPen as fasPen,
  faPencil as fasPencil,
  faPencilAlt as fasPencilAlt,
  faPenSquare as fasPenSquare,
  faPhone as fasPhone,
  faPieChart as fasPieChart,
  faPiggyBank as fasPiggyBank,
  faPlane as fasPlane,
  faPlayCircle as fasPlayCircle,
  faPlug as fasPlug,
  faPlus as fasPlus,
  faPresentation as fasPresentation,
  faPresentationScreen as fasPresentationScreen,
  faQuestion as fasQuestion,
  faQuestionCircle as fasQuestionCircle,
  faRedo as fasRedo,
  faRingsWedding as fasRingsWedding,
  faRoute as fasRoute,
  faScaleBalanced as fasScaleBalanced,
  faSearch as fasSearch,
  faShareAlt as fasShareAlt,
  faShield as fasShield,
  faShoppingCart as fasShoppingCart,
  faSignOut as fasSignOut,
  faSitemap as fasSitemap,
  faSlidersUp as fasSlidersUp,
  faSmile as fasSmile,
  faSort as fasSort,
  faSortDown as fasSortDown,
  faSortAlt as fasSortAlt,
  faSortAmountDown as fasSortAmountDown,
  faSortAmountUp as fasSortAmountUp,
  faSortSizeUp as fasSortSizeUp,
  faSortUp as fasSortUp,
  faSpinner as fasSpinner,
  faSquare as fasSquare,
  faSyncAlt as fasSyncAlt,
  faTable as fasTable,
  faTableCellsLarge as fasTableCellsLarge,
  faTachometerAlt as fasTachometerAlt,
  faTags as fasTags,
  faText as fasText,
  faTextHeight as fasTextHeight,
  faTimes as fasTimes,
  faToggleOn as fasToggleOn,
  faTrash as fasTrash,
  faTrashAlt as fasTrashAlt,
  faTreeAlt as fasTreeAlt,
  faTrophy as fasTrophy,
  faUmbrella as fasUmbrella,
  faUnderline as fasUnderline,
  faUndo as fasUndo,
  faUniversity as fasUniversity,
  faUpload as fasUpload,
  faUser as fasUser,
  faUserCircle as fasUserCircle,
  faUserCog as fasUserCog,
  faUserGraduate as fasUserGraduate,
  faUserPlus as fasUserPlus,
  faUsers as fasUsers,
  faUsersClass as fasUsersClass,
  faUserShield as fasUserShield,
  faUserSlash as fasUserSlash,
  faUserTie as fasUserTie,
  faVenus as fasVenus,
  faWallet as fasWallet,
  faWindowMinimize as fasWindowMinimize,
  faWindowMaximize as fasWindowMaximize,
  faWrench as fasWrench,
  faXmark as fasXmark
} from "@fortawesome/pro-solid-svg-icons";

// Do not add to this list, try using regular
import {
  faAlignLeft as falAlignLeft,
  faAngleRight as falAngleRight,
  faArchive as falArchive,
  faArrowLeft as falArrowLeft,
  faArrowRight as falArrowRight,
  faArrowsAltV as falArrowsAltV,
  faArrowUpRightFromSquare as falArrowUpRightFromSquare,
  faBaby as falBaby,
  faBalanceScale as falBalanceScale,
  faBars as falBars,
  faBell as falBell,
  faBinoculars as falBinoculars,
  faBriefcase as falBriefcase,
  faBriefcaseMedical as falBriefcaseMedical,
  faBuilding as falBuilding,
  faBullseyeArrow as falBullseyeArrow,
  faBullseyePointer as falBullseyePointer,
  faCalendar as falCalendar,
  faCalendarAlt as falCalendarAlt,
  faCalendarRange as falCalendarRange,
  faCar as falCar,
  faCaretDown as falCaretDown,
  faCaretUp as falCaretUp,
  faChartBar as falChartBar,
  faChartLine as falChartLine,
  faCheck as falCheck,
  faChevronDown as falChevronDown,
  faChevronUp as falChevronUp,
  faChild as falChild,
  faCircle as falCircle,
  faCircleCheck as falCircleCheck,
  faCircleExclamation as falCircleExclamation,
  faCircleInfo as falCircleInfo,
  faCirclePlus as falCirclePlus,
  faCircleQuestion as falCircleQuestion,
  faCircleXmark as falCircleXmark,
  faClipboard as falClipboard,
  faClipboardListCheck as falClipboardListCheck,
  faClose as falClose,
  faCloudDownload as falCloudDownload,
  faCocktail as falCocktail,
  faCog as falCog,
  faCogs as falCogs,
  faCommentAlt as falCommentAlt,
  faCommentAltLines as falCommentAltLines,
  faCommentsAlt as falCommentsAlt,
  faCopy as falCopy,
  faCreditCardFront as falCreditCardFront,
  faCross as falCross,
  faDatabase as falDatabase,
  faDonate as falDonate,
  faDownload as falDownload,
  faEdit as falEdit,
  faEllipsis as falEllipsis,
  faEnvelope as falEnvelope,
  faEuroSign as falEuroSign,
  faExchange as falExchange,
  faExclamationCircle as falExclamationCircle,
  faExclamationTriangle as falExclamationTriangle,
  faExpandAlt as falExpandAlt,
  faExternalLink as falExternalLink,
  faEye as falEye,
  faEyeSlash as falEyeSlash,
  faFeatherAlt as falFeatherAlt,
  faFile as falFile,
  faFileAlt as falFileAlt,
  faFileChartLine as falFileChartLine,
  faFileContract as falFileContract,
  faFileDownload as falFileDownload,
  faFileLines as falFileLines,
  faFilePdf as falFilePdf,
  faFileExcel as falFileExcel,
  faFilePlus as falFilePlus,
  faFileUpload as falFileUpload,
  faFileExport as falFileExport,
  faFilter as falFilter,
  faFlag as falFlag,
  faFolderPlus as falFolderPlus,
  faFolders as falFolders,
  faFolderTree as falFolderTree,
  faGavel as falGavel,
  faGear as falGear,
  faGem as falGem,
  faGift as falGift,
  faGlobe as falGlobe,
  faGlobeEurope as falGlobeEurope,
  faGraduationCap as falGraduationCap,
  faHandHolding as falHandHolding,
  faHandHoldingHand as falHandHoldingHand,
  faHandHoldingHeart as falHandHoldingHeart,
  faHandHoldingSeedling as falHandHoldingSeedling,
  faHandPointDown as falHandPointDown,
  faHandPointLeft as falHandPointLeft,
  faHandPointUp as falHandPointUp,
  faHandshakeAlt as falHandshakeAlt,
  faHeart as falHeart,
  faHeartCircle as falHeartCircle,
  faHome as falHome,
  faHouse as falHouse,
  faImage as falImage,
  faIndustryAlt as falIndustryAlt,
  faInfo as falInfo,
  faInfoCircle as falInfoCircle,
  faInfoSquare as falInfoSquare,
  faIslandTropical as falIslandTropical,
  faKey as falKey,
  faLandmark as falLandmark,
  faLanguage as falLanguage,
  faLightbulb as falLightbulb,
  faLineHeight as falLineHeight,
  faLink as falLink,
  faList as falList,
  faListCheck as falListCheck,
  faLocationCircle as falLocationCircle,
  faLock as falLock,
  faLockOpen as falLockOpen,
  faMagnifyingGlass as falMagnifyingGlass,
  faMap as falMap,
  faMapMarkerAlt as falMapMarkerAlt,
  faMapSigns as falMapSigns,
  faMarker as falMarker,
  faMars as falMars,
  faMegaphone as falMegaphone,
  faMinus as falMinus,
  faMoneyCheck as falMoneyCheck,
  faPalette as falPalette,
  faPencil as falPencil,
  faPencilAlt as falPencilAlt,
  faPenSquare as falPenSquare,
  faPhone as falPhone,
  faPieChart as falPieChart,
  faPiggyBank as falPiggyBank,
  faPlane as falPlane,
  faPlayCircle as falPlayCircle,
  faPlug as falPlug,
  faPlus as falPlus,
  faPresentation as falPresentation,
  faPresentationScreen as falPresentationScreen,
  faQuestion as falQuestion,
  faQuestionCircle as falQuestionCircle,
  faRingsWedding as falRingsWedding,
  faRoute as falRoute,
  faScaleBalanced as falScaleBalanced,
  faSearch as falSearch,
  faShareAlt as falShareAlt,
  faShield as falShield,
  faShoppingCart as falShoppingCart,
  faSignOut as falSignOut,
  faSitemap as falSitemap,
  faSlidersUp as falSlidersUp,
  faSmile as falSmile,
  faSpinner as falSpinner,
  faSquare as falSquare,
  faTableCellsLarge as falTableCellsLarge,
  faTachometerAlt as falTachometerAlt,
  faTags as falTags,
  faText as falText,
  faTextSize as falTextSize,
  faTimes as falTimes,
  faToggleOn as falToggleOn,
  faTrash as falTrash,
  faTrashAlt as falTrashAlt,
  faTreeAlt as falTreeAlt,
  faTrophy as falTrophy,
  faUmbrella as falUmbrella,
  faUndo as falUndo,
  faUniversity as falUniversity,
  faUpload as falUpload,
  faUser as falUser,
  faUserCircle as falUserCircle,
  faUserCog as falUserCog,
  faUserGraduate as falUserGraduate,
  faUserPlus as falUserPlus,
  faUsers as falUsers,
  faUsersClass as falUsersClass,
  faUserShield as falUserShield,
  faUserSlash as falUserSlash,
  faUserTie as falUserTie,
  faVenus as falVenus,
  faWallet as falWallet,
  faWrench as falWrench,
  faXmark as falXmark
} from "@fortawesome/pro-light-svg-icons";

import { library, dom } from "@fortawesome/fontawesome-svg-core";

export default function setupFontAwesome() {
  library.add(
    faAlignLeft,
    faAngleRight,
    faAngleDown,
    faArchive,
    faArrowUp,
    faArrowDown,
    faArrowsUpDown,
    faArrowLeft,
    faArrowRight,
    faArrowsAltV,
    faArrowUpRightFromSquare,
    faBaby,
    faBalanceScale,
    faBars,
    faBell,
    faBinoculars,
    faBriefcase,
    faBriefcaseMedical,
    faBuilding,
    faBullseyeArrow,
    faBullseyePointer,
    faCalendar,
    faCalendarAlt,
    faCalendarRange,
    faCar,
    faCaretDown,
    faCaretUp,
    faChartBar,
    faChartLine,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faChild,
    faCircle,
    faCircleCheck,
    faCircleExclamation,
    faCircleInfo,
    faCirclePlus,
    faCircleQuestion,
    faCircleXmark,
    faClipboard,
    faClipboardListCheck,
    faClose,
    faCloud,
    faCloudDownload,
    faCocktail,
    faCog,
    faCommentAlt,
    faCommentAltLines,
    faCommentsAlt,
    faCreditCardFront,
    faCross,
    faDatabase,
    faDonate,
    faDownload,
    faEdit,
    faEllipsis,
    faEnvelope,
    faEuroSign,
    faExchange,
    faExclamationCircle,
    faExclamationTriangle,
    faExpandAlt,
    faExternalLink,
    faEye,
    faEyeSlash,
    faFeatherAlt,
    faFile,
    faFileAlt,
    faFileChartLine,
    faFileContract,
    faFileDownload,
    faFileLines,
    faFilePdf,
    faFileExcel,
    faFileExport,
    faFilePlus,
    faFileUpload,
    faFilter,
    faFlag,
    faFolderPlus,
    faFolders,
    faFolderTree,
    faGavel,
    faGear,
    faGem,
    faGift,
    faGlobe,
    faGlobeEurope,
    faGraduationCap,
    faHandHolding,
    faHandHoldingHand,
    faHandHoldingHeart,
    faHandHoldingSeedling,
    faHandHoldingUsd,
    faHandPointDown,
    faHandPointLeft,
    faHandPointUp,
    faHandshakeAlt,
    faHeart,
    faHeartCircle,
    faHome,
    faHouse,
    faIndustryAlt,
    faInfo,
    faInfoCircle,
    faInfoSquare,
    faIslandTropical,
    faKey,
    faLandmark,
    faLanguage,
    faLightbulb,
    faLineHeight,
    faLink,
    faList,
    faListCheck,
    faLocationCircle,
    faLock,
    faMagnifyingGlass,
    faMap,
    faMapMarkerAlt,
    faMapSigns,
    faMarker,
    faMars,
    faMegaphone,
    faMinus,
    faMicrochipAi,
    faMoneyCheck,
    faPalette,
    faPen,
    faPencil,
    faPencilAlt,
    faPenSquare,
    faPhone,
    faPieChart,
    faPiggyBank,
    faPlane,
    faPlayCircle,
    faPlug,
    faPlus,
    faPresentation,
    faPresentationScreen,
    faQuestion,
    faQuestionCircle,
    faRingsWedding,
    faRoute,
    faScaleBalanced,
    faSearch,
    faShareAlt,
    faShield,
    faShoppingCart,
    faSignOut,
    faSitemap,
    faSlidersUp,
    faSmile,
    faSortAmountDown,
    faSortAmountUp,
    faSpinner,
    faSquare,
    faSyncAlt,
    faTableCellsLarge,
    faTachometerAlt,
    faTags,
    faText,
    faTimes,
    faToggleOn,
    faTrash,
    faTrashAlt,
    faTreeAlt,
    faTrophy,
    faUmbrella,
    faUndo,
    faUniversity,
    faUpload,
    faUser,
    faUserCircle,
    faUserCog,
    faUserGraduate,
    faUserPlus,
    faUsers,
    faUsersClass,
    faUserShield,
    faUserSlash,
    faUserTie,
    faVenus,
    faWallet,
    faWindowMaximize,
    faWrench,
    faXmark
  );
  library.add(
    fasAlignLeft,
    fasAngleRight,
    fasArchive,
    fasArrowLeft,
    fasArrowRight,
    fasArrowUp,
    fasArrowsAltV,
    fasArrowUpRightFromSquare,
    fasAsterisk,
    fasBaby,
    fasBalanceScale,
    fasBars,
    fasBell,
    fasBinoculars,
    fasBold,
    fasBriefcase,
    fasBriefcaseMedical,
    fasBuilding,
    fasBullseyeArrow,
    fasBullseyePointer,
    fasCalendar,
    fasCalendarAlt,
    fasCalendarRange,
    fasCar,
    fasCaretDown,
    fasCaretRight,
    fasCaretUp,
    fasChartArea,
    fasChartBar,
    fasChartLine,
    fasChartPie,
    fasCheck,
    fasCheckSquare,
    fasChevronDoubleRight,
    fasChevronDown,
    fasChevronLeft,
    fasChevronRight,
    fasChevronUp,
    fasChild,
    fasCircle,
    fasCircleCheck,
    fasCircleExclamation,
    fasCircleInfo,
    fasCirclePlus,
    fasCircleQuestion,
    fasCircleXmark,
    fasClipboard,
    fasClipboardListCheck,
    fasClose,
    fasCloudDownload,
    fasCocktail,
    fasCog,
    fasColumns,
    fasCommentAlt,
    fasCommentAltLines,
    fasCommentsAlt,
    fasCreditCardFront,
    fasCross,
    fasDatabase,
    fasDonate,
    fasDownload,
    fasEdit,
    fasEllipsis,
    fasEnvelope,
    fasEuroSign,
    fasExchange,
    fasExchangeAlt,
    fasExclamationCircle,
    fasExclamationTriangle,
    fasExpandAlt,
    fasExternalLink,
    fasEye,
    fasEyeSlash,
    fasFeatherAlt,
    fasFile,
    fasFileAlt,
    fasFileChartLine,
    fasFileContract,
    fasFileDownload,
    fasFileExport,
    fasFileImport,
    fasFileLines,
    fasFilePdf,
    fasFilePlus,
    fasFileUpload,
    fasFilter,
    fasFlag,
    fasFolderPlus,
    fasFolders,
    fasFolderTree,
    fasFont,
    fasGavel,
    fasGear,
    fasGem,
    fasGift,
    fasGlobe,
    fasGlobeEurope,
    fasGraduationCap,
    fasGripLines,
    fasGripLinesVertical,
    fasHandHolding,
    fasHandHoldingHand,
    fasHandHoldingHeart,
    fasHandHoldingSeedling,
    fasHandPointDown,
    fasHandPointLeft,
    fasHandPointUp,
    fasHandshakeAlt,
    fasHeading,
    fasHeart,
    fasHeartCircle,
    fasHighlighter,
    fasHome,
    fasHouse,
    fasImage,
    fasIndustryAlt,
    fasInfo,
    fasInfoCircle,
    fasInfoSquare,
    fasIslandTropical,
    fasItalic,
    fasKey,
    fasLandmark,
    fasLanguage,
    fasLightbulb,
    fasLineHeight,
    fasLink,
    fasList,
    fasListCheck,
    fasListOl,
    fasListUl,
    fasLocationCircle,
    fasLock,
    fasLockOpen,
    fasMagnifyingGlass,
    fasMap,
    fasMapMarkerAlt,
    fasMapSigns,
    fasMarker,
    fasMars,
    fasMegaphone,
    fasMinus,
    fasMinusCircle,
    fasMoneyCheck,
    fasPageBreak,
    fasPalette,
    fasPaperclip,
    fasPen,
    fasPencil,
    fasPencilAlt,
    fasPenSquare,
    fasPhone,
    fasPieChart,
    fasPiggyBank,
    fasPlane,
    fasPlayCircle,
    fasPlug,
    fasPlus,
    fasPresentation,
    fasPresentationScreen,
    fasQuestion,
    fasQuestionCircle,
    fasRedo,
    fasRingsWedding,
    fasRoute,
    fasScaleBalanced,
    fasSearch,
    fasShareAlt,
    fasShield,
    fasShoppingCart,
    fasSignOut,
    fasSitemap,
    fasSlidersUp,
    fasSmile,
    fasSortAlt,
    fasSort,
    fasSortAmountDown,
    fasSortAmountUp,
    fasSortDown,
    fasSortSizeUp,
    fasSortUp,
    fasSpinner,
    fasSquare,
    fasSyncAlt,
    fasTable,
    fasTableCellsLarge,
    fasTachometerAlt,
    fasTags,
    fasText,
    fasTextHeight,
    fasTimes,
    fasToggleOn,
    fasTrash,
    fasTrashAlt,
    fasTreeAlt,
    fasTrophy,
    fasUmbrella,
    fasUnderline,
    fasUndo,
    fasUniversity,
    fasUpload,
    fasUser,
    fasUserCircle,
    fasUserCog,
    fasUserGraduate,
    fasUserPlus,
    fasUsers,
    fasUsersClass,
    fasUserShield,
    fasUserSlash,
    fasUserTie,
    fasVenus,
    fasWallet,
    fasWindowMaximize,
    fasWindowMinimize,
    fasWrench,
    fasXmark
  );
  library.add(
    falAlignLeft,
    falAngleRight,
    falArchive,
    falArrowLeft,
    falArrowRight,
    falArrowsAltV,
    falArrowUpRightFromSquare,
    falBaby,
    falBalanceScale,
    falBars,
    falBell,
    falBinoculars,
    falBriefcase,
    falBriefcaseMedical,
    falBuilding,
    falBullseyeArrow,
    falBullseyePointer,
    falCalendar,
    falCalendarAlt,
    falCalendarRange,
    falCar,
    falCaretDown,
    falCaretUp,
    falChartBar,
    falChartLine,
    falCheck,
    falChevronDown,
    falChevronUp,
    falChild,
    falCircle,
    falCircleCheck,
    falCircleExclamation,
    falCircleInfo,
    falCirclePlus,
    falCircleQuestion,
    falCircleXmark,
    falClipboard,
    falClipboardListCheck,
    falClose,
    falCloudDownload,
    falCocktail,
    falCog,
    falCogs,
    falCommentAlt,
    falCommentAltLines,
    falCommentsAlt,
    falCopy,
    falCreditCardFront,
    falCross,
    falDatabase,
    falDonate,
    falDownload,
    falEdit,
    falEllipsis,
    falEnvelope,
    falEuroSign,
    falExchange,
    falExclamationCircle,
    falExclamationTriangle,
    falExpandAlt,
    falExternalLink,
    falEye,
    falEyeSlash,
    falFeatherAlt,
    falFile,
    falFileAlt,
    falFileChartLine,
    falFileContract,
    falFileDownload,
    falFileLines,
    falFilePdf,
    falFileExcel,
    falFilePlus,
    falFileUpload,
    falFileExport,
    falFilter,
    falFlag,
    falFolderPlus,
    falFolders,
    falFolderTree,
    falGavel,
    falGear,
    falGem,
    falGift,
    falGlobe,
    falGlobeEurope,
    falGraduationCap,
    falHandHolding,
    falHandHoldingHand,
    falHandHoldingHeart,
    falHandHoldingSeedling,
    falHandPointDown,
    falHandPointLeft,
    falHandPointUp,
    falHandshakeAlt,
    falHeart,
    falHeartCircle,
    falHome,
    falHouse,
    falImage,
    falIndustryAlt,
    falInfo,
    falInfoCircle,
    falInfoSquare,
    falIslandTropical,
    falKey,
    falLandmark,
    falLanguage,
    falLightbulb,
    falLineHeight,
    falLink,
    falList,
    falListCheck,
    falLocationCircle,
    falLock,
    falLockOpen,
    falMagnifyingGlass,
    falMap,
    falMapMarkerAlt,
    falMapSigns,
    falMarker,
    falMars,
    falMegaphone,
    falMinus,
    falMoneyCheck,
    falPalette,
    falPencil,
    falPencilAlt,
    falPenSquare,
    falPhone,
    falPieChart,
    falPiggyBank,
    falPlane,
    falPlayCircle,
    falPlug,
    falPlus,
    falPresentation,
    falPresentationScreen,
    falQuestion,
    falQuestionCircle,
    falRingsWedding,
    falRoute,
    falScaleBalanced,
    falSearch,
    falShareAlt,
    falShield,
    falShoppingCart,
    falSignOut,
    falSitemap,
    falSlidersUp,
    falSmile,
    falSpinner,
    falSquare,
    falTableCellsLarge,
    falTachometerAlt,
    falTags,
    falText,
    falTextSize,
    falTimes,
    falToggleOn,
    falTrash,
    falTrashAlt,
    falTreeAlt,
    falTrophy,
    falUmbrella,
    falUndo,
    falUniversity,
    falUpload,
    falUser,
    falUserCircle,
    falUserCog,
    falUserGraduate,
    falUserPlus,
    falUsers,
    falUsersClass,
    falUserShield,
    falUserSlash,
    falUserTie,
    falVenus,
    falWallet,
    falWrench,
    falXmark
  );
  dom.watch();
}

document.fonts.ready
  .then(() => {
    // Font Awesome and other fonts should be loaded by now
    const faIcons = document.querySelectorAll("g.missing");

    faIcons.forEach(icon => {
      const iconName = [...icon.parentElement.classList].filter(className =>
        className.startsWith("fa-")
      )[0];
      console.error(`Icon not loaded: ${iconName}`);
    });
  })
  .catch(() => {
    console.error("Fonts not ready");
  });
