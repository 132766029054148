import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    interval: { type: Number, default: 60 },
    activePath: String,
    timeoutPath: String
  };

  connect() {
    this.timeoutRequestInterval = setInterval(
      this.pingActiveEndpoint.bind(this),
      this.intervalValue * 1000
    )
  }

  disconnect() {
    clearInterval(this.timeoutRequestInterval);
  }

  pingActiveEndpoint() {
    fetch(this.activePathValue, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data === false || data === 'false' || data === null) {
          window.location.href = this.timeoutPathValue;
        }
      })
      .catch(error => {
        console.error('Session check error:', error);
      });

  }

}
