import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { id: String, opened: false, groupId: String };

  markAsReadAndFollow(event) {
    event.preventDefault();
    const url = event.currentTarget.href;
    const shouldOpenInNewTab = event.currentTarget.target === "_blank";
    this.markAsRead({ track: "attachment" })
      .then(() => {
        if (shouldOpenInNewTab) {
          window.open(url, "_blank");
        } else {
          window.location.href = url;
        }
      })
      .catch(error => console.error("Error marking as read:", error));
  }

  markAsRead(options = {}) {
    const notificationId = this.idValue;
    const params = {};
    if (this.hasGroupIdValue) {
      params.group_id = this.groupIdValue;
    }
    const currentLocale = document.documentElement.lang || "en";
    if (options.track) {
      params.track = options.track;
    }
    return fetch(`/temporary_notifications/${notificationId}?locale=${currentLocale}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content,
        "Accept": "text/vnd.turbo-stream.html"
      },
      body: JSON.stringify(params),
    }).then(response => {
      if (!response.ok) throw new Error("Failed to mark as read");
      return response.text();
    }).then(html => {
      Turbo.renderStreamMessage(html);
    }).catch(error => {
      console.error("Error updating notification:", error);
    });
  }

  markAllAsRead() {
    const currentLocale = document.documentElement.lang || "en";
    const url = new URL("/temporary_notifications/mark_as_read", window.location.origin);
    url.searchParams.append("locale", currentLocale);

    const bodyParams = {};

    if (this.hasGroupIdValue) {
      bodyParams.group_id = this.groupIdValue;
    }

    fetch(url.toString(), {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content,
        Accept: "text/vnd.turbo-stream.html",
      },
      body: JSON.stringify(bodyParams),
    })
      .then((response) => {
        if (!response.ok) throw new Error("Failed to mark all as read");
        return response.text();
      })
      .then((html) => {
        Turbo.renderStreamMessage(html);
      })
      .catch((error) => {
        console.error("Error updating notifications:", error);
      });
  }
}
