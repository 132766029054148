import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggle", "menu"]


  connect() {
    if (this.hasToggleTarget) {
      this.toggleTarget.addEventListener("change", this.handleToggle);
    }
  }

  disconnect() {
    if (this.hasToggleTarget) {
      this.toggleTarget.removeEventListener("change", this.handleToggle);
    }
    document.removeEventListener("click", this.closeOnClickOutside);
  }

  handleToggle = () => {
    if (!this.hasToggleTarget) return;

    if (this.toggleTarget.checked) {
      document.addEventListener("click", this.closeOnClickOutside);
    } else {
      document.removeEventListener("click", this.closeOnClickOutside);
    }
  };

  closeOnClickOutside = (event) => {
    if (!this.hasMenuTarget || !this.hasToggleTarget) return;

    requestAnimationFrame(() => {
      if (this.menuTarget.contains(event.target) || this.toggleTarget.contains(event.target)) {
        return;
      }

      this.toggleTarget.checked = false;
      document.removeEventListener("click", this.closeOnClickOutside);
    });
  };
}