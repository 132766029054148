export type FreshdeskConfig = {
  authorized?: boolean;
  name?: string;
  email?: string;
};

declare global {
  interface Window {
    FreshworksWidget: Function;
    I18n: {
      t: (key: string) => string;
      locale: string;
    };
    fwSettings: {
      widget_id: number;
      locale: string;
    };
  }
}

function freshdeskTranslations(locale: string) {
  return {
    banner: window.I18n.t("freshdesk.banner"),
    launcher: window.I18n.t("freshdesk.launcher"),
    contact_form: {
      title: window.I18n.t("freshdesk.contact_form.title"),
      submit: window.I18n.t("freshdesk.contact_form.submit"),
      confirmation: window.I18n.t("freshdesk.contact_form.confirmation")
    },
    frustration_tracking: {
      banner: window.I18n.t("freshdesk.frustration_tracking.banner"),
      description: window.I18n.t("freshdesk.frustration_tracking.description"),
      confirmation: window.I18n.t("freshdesk.frustration_tracking.confirmation")
    }
  };
}

export const loadFreshdesk = (config: FreshdeskConfig) => {
  if (!window || !config) return;

  const { authorized, name, email } = config;

  window.fwSettings = {
    widget_id: authorized ? 80000001255 : 80000001968,
    locale: window.I18n.locale
  };

  // Funky self calling function code from freshworks
  // Reset if called twice
  window.FreshworksWidget = null;
  !(function() {
    if ("function" != typeof window.FreshworksWidget) {
      const n = function(...args) {
        n.q.push(args);
      };
      n.q = [];
      window.FreshworksWidget = n;
    }
  })();

  window.FreshworksWidget("setLabels", {
    en: freshdeskTranslations("en"),
    fr: freshdeskTranslations("fr"),
    nl: freshdeskTranslations("nl"),
    de: freshdeskTranslations("de")
  });
  window.FreshworksWidget("identify", "ticketForm", { name, email });
  window.FreshworksWidget("hide", "launcher");
};
