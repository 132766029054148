import { Controller } from "@hotwired/stimulus";

import { formatUnixWithoutTime } from "@utilities/converter";
import { post } from "@rails/request.js";

// The usage of this controller asumes that webix is available on window as a global script import
// As the webix team does not recommend bundling webix with a bundler
// see here https://webix.gitbook.io/webix-jet/part-iv-toolchain/importing-webix-as-module

export default class extends Controller {
  static targets = [
    "content",
    "details",
    "unreadFilesCount",
    "uploader",
    "actions",
    "tour",
    "tourStart",
    "uploadInfo"
  ];
  static values = {
    groupId: String,
    rootFolderId: String,
    unreadFilesCount: Number,
    readonly: Boolean,
    onboardingSeen: Boolean,
    layout: String,
    commentsEnabled: Boolean,
    filesUrl: String,
    usersUrl: String,
    membersUrl: String,
    currentMemberId: String,
    currentUserId: String,
    aiDocumentChatEnabled: Boolean
  };

  /* This code is mainly taken from the older filemanager.js code */
  /* It's very intricate and needs further cleanup in the future */
  /* Ideally we completely move away from webix at one point */

  initialize() {
    this.filemanager = null;
    this.filemanagerTour = null;
    this.filemanagerTourStep = 1;
    this.detailsWindow = null;
    this.uploadInfoWindow = null;
    this.restrictableAccessForm = null;
    this.internalUploader = null;
    this.tree = {};
    this.actions = null;
    this.currentAttachment = null;
    this.confirmed = false;
    // this.initialLoad = true;

    this.initialPath = null;
    var state = decodeURIComponent(window.location.hash);

    if (this.expanded && state && state.indexOf("#!/") === 0) {
      this.initialPath = state.replace("#!/", "");
    }

    this.initializeWebix();
  }

  initializeWebix() {
    webix.Touch.config.longTouchDelay = 200;
    if (this.mobile) {
      webix.CustomScroll.init();
      webix.skin.$active.inputHeight = 60;
    }
    webix.i18n.locales["nl"] = {
      groupDelimiter: ".",
      groupSize: 3,
      decimalDelimiter: ",",
      decimalSize: 2,
      price: "€ {obj}",
      priceSettings: {
        groupDelimiter: ".",
        groupSize: 3,
        decimalDelimiter: ",",
        decimalSize: 2
      },
      message: {
        ok: "OK",
        cancel: "Annuleren"
      },
      comments: {
        send: "Sturen",
        confirmMessage: "Het commentaar zal verwijderd worden. Bent u zeker?",
        edit: "Bewerken",
        remove: "Verwijderen",
        placeholder: "Schrijf hier..",
        moreComments: "Meer opmerkingen"
      },
      hint: {
        next: "Volgende",
        prev: "Vorige",
        last: "Eindigen"
      }
    };

    webix.i18n.locales["fr-FR"] = {
      fullDateFormat: "%m/%d/%Y %h:%i %A"
    };

    webix.i18n.locales["de"] = {
      fullDateFormat: "%m/%d/%Y %h:%i %A"
    };

    var webixLocale = I18n.locale.includes("fr") ? "fr-FR" : I18n.locale;

    webix.i18n.setLocale(webixLocale);
    const filemanagerLocale = I18n.locale.includes("fr-FR") ? "fr" : I18n.locale;
    webix.i18n.filemanager = I18n.translations[filemanagerLocale]["filemanager"]["webix"] || {};

    //https://forum.webix.com/discussion/4263/csrf-token
    webix.attachEvent("onBeforeAjax", function(mode, url, data, request, headers, files, promise) {
      headers["Accept"] =
        "*/*;q=0.5, text/javascript, application/javascript, application/ecmascript, application/x-ecmascript";
      //headers["Content-type"] = "application/json";
      headers["X-Requested-With"] = "XMLHttpRequest";
      headers["X-CSRF-Token"] = document.getElementsByName("csrf-token")[0].content;
    });

    var _this = this;

    webix.proxy.saveComment = {
      $proxy: true,
      save: function(view, update, dp) {
        var data = update.data;
        let [commentableType, commentableId] = this.source.split("/");
        data.commentable_type = commentableType;

        if (commentableType === "attachment" && _this.currentAttachment) {
          data.commentable_id = _this.currentAttachment;
        } else if (commentableId) {
          data.commentable_id = commentableId;
        } else {
          return false;
        }

        if (data.skip_save) {
        } else if (update.operation === "insert") {
          webix.ajax().post("/comments", data);
        } else if (update.operation === "update") {
          webix.ajax().patch("/comments/" + data.id, data);
        } else if (update.operation === "delete" && !skipDelete) {
          webix.ajax().del("/comments/" + data.id, data);
        }
      }
    };

    webix.proxy.loadComments = {
      $proxy: true,
      load: function(view, params) {
        var params = params || {};
        let [commentableType, commentableId] = this.source.split("/");
        params.commentable_type = commentableType;
        if (commentableType === "attachment" && _this.currentAttachment) {
          params.commentable_id = _this.currentAttachment;
          return webix.ajax().get("/comments", params);
        } else if (commentableId) {
          params.commentable_id = commentableId;
          return webix.ajax().get("/comments", params);
        } else {
          return false;
        }
      }
    };
  }

  connect() {
    this.element[this.identifier] = this;

    if (this.mobile) {
      this.applyMobileWebixOverrides();
    }

    this.constructFileManager();
    this.constructDetailsWindow();
    this.constructUploadInfoWindow();
    this.updateUnreadFilesCount();
    this.attachLoadingListener();
    this.attachResizeListener();
    this.applyWebixMonkeyPatches();
    return this.loadFiles();
  }

  reset() {
    this.disconnect();
    this.initialize();
    this.connect();
  }

  get tourSteps() {
    return [
      {
        // step 1
        el: ".webix-fm-step-1",
        title: I18n.t("hint.fm.step_welcome_title"),
        padding: 5,
        eventEl: "button",
        text: I18n.t("hint.fm.step_welcome_body"),
        event: "click",
        next: () => {
          return this.filemanager.waitData;
        }
      },
      {
        // step 2
        el: ".webix_view.webix_tree.webix_fmanager_tree .webix_scroll_cont",
        eventEl: "button",
        title: I18n.t("hint.fm.step_left_tree_title"),
        text: I18n.t("hint.fm.step_left_tree_body"),
        event: "click"
      },
      {
        // step 3
        el: ".webix_ss_header tr[section='header'] td:nth-child(3)",
        eventEl: "button",
        title: I18n.t("hint.fm.step_added_by_title"),
        text: I18n.t("hint.fm.step_added_by_body"),
        event: "click"
      },
      {
        // step 4
        el: ".filemanager__uploader-menu",
        eventEl: "button",
        title: I18n.t("hint.fm.step_upload_file_title"),
        text: I18n.t("hint.fm.step_upload_file_body"),
        event: "click"
      },
      {
        // step 5
        el: ".webix_column.webix_first .webix_cell:nth-child(1)",
        eventEl: "button",
        title: I18n.t("hint.fm.step_right_click_title"),
        text: I18n.t("hint.fm.step_right_click_body"),
        event: "click",
        next: () => {
          return webix.$$("$filemenu1").show({
            x: 400, //left offset from the right side
            y: 150 //top offset
          });
        }
      },
      {
        // step 6
        el: "$filemenu1",
        eventEl: "button",
        title: I18n.t("hint.fm.step_options_menu_title"),
        text: I18n.t("hint.fm.step_options_menu_body"),
        event: "click",
        next: () => {
          $(".webix_column.webix_first .webix_cell:nth-child(1)").trigger("click");
          this.filemanager.showDetails();
        }
      },
      {
        // step 7
        el: "filemanager-details",
        eventEl: "button",
        title: I18n.t("hint.fm.step_details_title"),
        text: I18n.t("hint.fm.step_details_body"),
        event: "click"
      },
      {
        // step 8
        el: ".webix_fmanager_toolbar",
        eventEl: "button",
        title: I18n.t("hint.fm.step_navigation_title"),
        text: I18n.t("hint.fm.step_navigation_body"),
        event: "click"
      },
      {
        // step 9
        el: ".filemanager__capacity-usage",
        eventEl: "button",
        padding: 10,
        title: I18n.t("hint.fm.step_capacity-usage_title"),
        text: I18n.t("hint.fm.step_capacity-usage_body"),
        event: "click"
      },
      {
        // step 10
        el: ".backup-btn",
        eventEl: "button",
        padding: 5,
        title: I18n.t("hint.fm.step_backup_title"),
        text: I18n.t("hint.fm.step_backup_body"),
        event: "click"
      }
    ];
  }

  constructFileManagerTour() {
    // because this when there 403 file big from nginx, then seems like webix reload by itself causing this
    // running twice because this.filemanager.attachEvent("onAfterLoad", this.handleLoadingCompleted.bind(this));

    if (this.filemanagerTour) {
      return;
    }

    this.filemanagerTour = webix.ui({
      view: "hint",
      container: this.tourTarget,
      id: "filemanager_tour",
      prevButton: false
    });

    this.filemanagerTour.setSteps(this.tourSteps);

    webix.ui({
      container: this.tourStartTarget,
      id: "filemanager-onboarding-button",
      view: "icon",
      icon: "fal fa-play-circle",
      tooltip: I18n.t("hint.see_guided_tour"),
      css: "guided-tour-btn ml5",
      click: () => {
        this.filemanager.$$("modes").setValue("table");
        this.filemanager.$$("table").show();
        if (this.filemanagerTourStep === 1) {
          this.filemanagerTour.start();
        } else {
          this.filemanagerTour.resume(this.filemanagerTourStep);
        }
      }
    });

    this.filemanagerTour.attachEvent("onNext", step => {
      this.filemanagerTourStep = step;
    });

    this.filemanagerTour.attachEvent("onPrevious", step => {
      this.filemanagerTourStep = step;
    });

    this.filemanagerTour.attachEvent("onEnd", step => {
      this.filemanagerTourStep = 1;
    });

    if (!this.onboardingSeenValue) {
      this.filemanagerTour.start();
      this.markOnboardingSeen();
    }
  }

  applyMobileWebixOverrides() {
    webix.protoUI(
      {
        name: "fileview"
      },
      webix.EditAbility,
      webix.ui.list
    );

    webix.type(webix.ui.list, {
      name: "FileView",
      height: 50,
      scroll: true,
      $dragHTML: function(item, pos) {
        var ctx = webix.DragControl.getContext();
        var type = this.type;
        var text = type.dragTemplate(item, type);
        var size = webix.html.getTextSize(text);
        var posView = webix.html.offset(this.$view);
        var offset = pos.x - posView.x;
        ctx.x_offset = offset > size.width ? -size.width / 4 : -offset;
        ctx.y_offset = -size.height / 2;
        return (
          "<div class='webix_tree_item webix_fmanager_drag' style='width:auto'>" + text + "</div>"
        );
      },
      template: (object, common) => {
        var name = common.templateName(object, common);
        if (object.type != "folder") {
          var timestamp = `<span class='text-body-lite-small text-truncate text-muted'>${I18n.t(
            "filemanager.added"
          )} ${formatUnixWithoutTime(object.date, I18n.locale)}</span>`;
          let extensionRegex = /(?:\.([^.]+))?$/;
          var extension = extensionRegex.exec(name)[1] || "other";
          var icon = this.iconForExtension(extension);
          var fileItem = `<span class='d-flex flex-col min-w-0'><span class='text-body-lite text-truncate'>${name}</span>${timestamp}</span>`;
        } else {
          var countBadge = object.childCount
            ? `<span class='ml-2 badge text-white bg-primary'>${object.childCount}</span>`
            : "";
          var icon = `<div class='symbol symbol--small color-dark'><div class='webix_fmanager_icon fm-${common
            .icons[object.type] || common.icons.file} symbol__icon'></div></div>`;
          var fileItem = `<span class='d-flex justify-content-between'><span class='text-body-lite--bold text-truncate'>${name}</span><span>${countBadge}</span></span>`;
        }
        var file = `<span class='d-flex align-items-center grow overflow-hidden' data-webix-action='open'><span class='mr-4'>${icon}</span>${fileItem}</span>`;
        var actions =
          "<button class='btn color-main-font p-0_5' data-webix-action='context'><i class='fa-regular fa-ellipsis'> </i></button>";
        return `<div class='filemanager__item d-flex justify-content-between align-items-stretch'>${file}${actions}</div>`;
      }
    });
  }

  iconForExtension(extension) {
    const colorMappings = {
      pdf: "pdf",
      xlsx: "excel",
      xls: "excel",
      csv: "excel",
      png: "image",
      jpg: "image",
      jpeg: "image",
      svg: "image",
      doc: "word",
      docx: "word",
      ppt: "ppt",
      zip: "zip"
    };

    let color = colorMappings[extension] || "other";

    return `<div class='symbol symbol--small symbol--square bg-color-${color} color-${color}'><span class='symbol__label'>${extension}</span></div>`;
  }

  constructFileManager() {
    this.filemanager = webix.ui(this.filemanagerConfig);
    if (this.docked) {
      this.filemanager.hideTree();
    }
    this.actions = this.filemanager.getMenu();

    if (this.mobile) {
      this.applyMobileFilemanagerPatches();
    }

    this.configureFilesSorting();

    this.configureInternalUploader();
  }

  configureFilesSorting() {
    // Make the files mode sort similarly to the table mode
    this.filemanager.$$("files").data.attachEvent("onBeforeSort", (by, dir, as, sort) => {
      this.filemanager.sortState = {
        view: this.filemanager.$$("files").config.id,
        sort: sort
      };

      if (this.filemanager.$searchResults && this.filemanager.$$("search")) {
        this.filemanager.showSearchResults(this.filemanager.$$("search").getValue());
        return false;
      }
    });
  }

  applyMobileFilemanagerPatches() {
    this.applyMobileStyleChanges();
    this.applyMobileEventHandlers();
    this.redefineMobileFilemanagerLayout();

    this.constuctMobileActionMenuHeader();
    this.actions.remove("edit");
  }

  redefineMobileFilemanagerLayout() {
    this.filemanager.$$("path").define("scroll", true);
    this.filemanager.$$("treeLayout").hide();
    this.filemanager.$$("menu").hide();
    this.filemanager.$$("up").hide();
    this.filemanager.$$("search").hide();
    this.filemanager.$$("back").hide();
    this.filemanager.$$("forward").hide();
    this.filemanager.$$("resizer").hide();
    this.filemanager.$$("menuSpacer").hide();
    this.filemanager.$$("modes").hide();

    // Disable selection in mobile
    this.filemanager.$$("files").define("select", false);
  }

  applyMobileStyleChanges() {
    webix.html.removeCss(this.actions.getNode(), "webix_fmanager_actions");
    webix.html.addCss(this.actions.getNode(), "filemanager__actions");

    webix.html.removeCss(this.filemanager.$$("toolbar").getNode(), "webix_fmanager_toolbar");
    webix.html.addCss(this.filemanager.$$("toolbar").getNode(), "filemanager__toolbar");
  }

  applyMobileEventHandlers() {
    this.actions.attachEvent("onHide", () => {
      this.element.classList.remove("filemanager__container--window-open");
    });

    this.actions.attachEvent("onShow", () => {
      this.element.classList.add("filemanager__container--window-open");
    });

    this.actions.attachTo(this.filemanager);

    this.filemanager.$$("files").detachEvent("onItemDblClick");
    // This events serves to select the file after right click context. This is not desired on mobile.
    this.filemanager.$$("files").detachEvent("onAfterMenuShow");
    this.filemanager.$$("files").detachEvent("onBeforeMenuShow");

    this.actions.detachEvent("onBeforeShow");

    this.actions.attachEvent("onBeforeShow", function(event) {
      if (webix.Touch._long_touched) {
        return false;
      } else {
        return true;
      }
    });

    this.filemanager.$$("files").attachEvent("onItemClick", (id, event, target) => {
      // Tried everything to make webix onClick with a class work, but halas couldn't find out why it doesn't.
      if (event.target.closest("[data-webix-action='context']")) {
        this.showMobileActionMenu(id, event);
      } else {
        this.filemanager._onFileDblClick(id);
      }
    });
  }

  constuctMobileActionMenuHeader() {
    // Hacky solution to get head to be displayed in a webix submenu
    //
    let headConfig = {
      view: "toolbar",
      borderless: true,
      cols: [
        {
          view: "template",
          template: I18n.t("filemanager.actions"),
          css: "p-0_5 text-title-3",
          borderless: true
        },
        {
          view: "icon",
          icon: "fas fa-times fa-lg",
          css: "filemanager__actions__close",
          align: "right",
          click: () => {
            this.actions.hide();
          }
        }
      ],
      height: 48
    };

    this.actions._head_cell = webix.ui(headConfig);
    this.actions._headobj.appendChild(this.actions._head_cell._viewobj);
    this.actions.adjust();
  }

  showMobileActionMenu(id, event) {
    var item = this.filemanager.getItem(id);
    let type = item.type;

    this.filemanager.$$("files").select(id);

    this.actions.setContext({
      id: id,
      obj: webix.$$(event)
    });

    this.actions.filter(actionObject => {
      if (!this.filemanager.config.menuFilter) {
        return true;
      }

      return this.filemanager.config.menuFilter(actionObject);
    });

    this.actions.show(event);
  }

  configureInternalUploader() {
    this.internalUploader = this.filemanager._uploader;
    this.internalUploader.config.autosend = false;

    this.internalUploader.attachEvent("onAfterFileAdd", (file, e) => {
      this.filemanager.hideProgress();

      var form = $$("upload-info-form");
      // Get the values that have been set on the details window for the current parent
      var parentFormValues = this.restrictableAccessForm.getValues();
      // And set those as defaults.
      var formValues = {
        value: file.name.replace(/'/g, "_"),
        access: parentFormValues.access || "public",
        members: parentFormValues.members || [this.currentMemberIdValue]
      };
      form.setValues(formValues);
      this.hideRenameFieldIfMultipleFiles(file);
      this.showUploadInfo();
    });

    this.internalUploader.attachEvent("onFileUpload", this.handleFileUpload.bind(this));

    this.internalUploader.attachEvent("onFileUploadError", this.handleFileUploadError.bind(this));

    this.internalUploader.attachEvent("onUploadComplete", this.handleUploadComplete.bind(this));
  }

  hideRenameFieldIfMultipleFiles(file) {
    const field = $$("filemanager-upload-info").getBody().elements.name || $$("filemanager-upload-info").getBody().elements.value;

    if (!field) return;
    if (this.internalUploader.files.data.count() > 1) {
      field.hide();
    } else {
      field.setValue(file.name);
    }
  }

  createFolderForCurrentFolder() {
    var url = this.filemanager.config.handlers.create.source;
    var id = this.filemanager.getCursor();
    webix.ajax().post(url, { action: "create", target: id }, (text, xml, xhr) => {
      this.addFolder(JSON.parse(text), id);
      this.filemanager.showDetails();
    });
  }

  uploadFileForCurrentFolder(event) {
    var id = this.filemanager.getCursor();
    var input = this.internalUploader.getInputNode();
    this.internalUploader.config.directory = false;
    input.removeAttribute("webkitdirectory");
    input.removeAttribute("mozdirectory");
    input.removeAttribute("directory");
    webix.ui.filemanager.prototype.uploadFile.call(this.filemanager, id, event);
  }

  get modalWidth() {
    return this.mobile ? 250 : 500;
  }

  applyWebixMonkeyPatches() {
    if (this.actions) {
      this.actions.remove("copy");
      // create will be replaced by custom create folder
      this.actions.remove("create");
    }

    this.filemanager.createFolder = (id, e) => {
      if (this.actions) {
        this.actions.hide();
      }
      var url = this.filemanager.config.handlers.create.source;
      webix.ajax().post(url, { action: "create", target: id }, (text, xml, xhr) => {
        this.addFolder(JSON.parse(text), id);
        this.filemanager.showDetails();
      });
    };

    this.filemanager.uploadFolder = (id, e) => {
      var input = this.internalUploader.getInputNode();
      this.internalUploader.config.directory = true;
      input.setAttribute("webkitdirectory", true);
      input.setAttribute("mozdirectory", true);
      input.setAttribute("directory", true);
      webix.ui.filemanager.prototype.uploadFile.call(this.filemanager, id, e);
    };

    //modify uploadFile method
    this.filemanager.uploadFile = (id, e) => {
      var input = this.internalUploader.getInputNode();
      this.internalUploader.config.directory = false;
      input.removeAttribute("webkitdirectory");
      input.removeAttribute("mozdirectory");
      input.removeAttribute("directory");
      webix.ui.filemanager.prototype.uploadFile.call(this.filemanager, id, e);
    };

    this.filemanager.confirmDelete = ids => {
      var blockMessage = I18n.t("filemanager.folder_cannot_be_deleted");
      var confirmMessage = "";
      var arrId = ids;
      if (ids.constructor !== Array) {
        arrId = [ids];
      }

      var item = this.filemanager.getItem(arrId[0]);
      if (item.type === "folder") {
        confirmMessage = I18n.t("filemanager.all_files_will_be_deleted");
      } else {
        confirmMessage = I18n.t("filemanager.file_will_be_deleted");
      }
      webix.confirm({
        title: I18n.t("js.are_you_sure"),
        text: confirmMessage,
        type: "confirm-warning",
        width: this.modalWidth,
        callback: result => {
          if (result) {
            for (var id of arrId) {
              this.performDelete(id);
            }
          }
          // hide the right-click menu
          webix.callEvent("onClick", []);
        }
      });
      return false;
    };

    this.filemanager.attachEvent("onItemSelect", id => {
      var item = this.filemanager.getItem(id);
      this.getItemInfo(item);
    });

    this.filemanager.attachEvent("onFolderSelect", id => {
      var item = this.filemanager.getItem(id);
      this.getItemInfo(item);
    });

    this.filemanager.attachEvent("onBeforeDeleteFile", ids => {
      this.filemanager.confirmDelete(ids);
      return false;
    });

    this.filemanager.attachEvent("onBeforeDrag", (context, event) => {
      let blockMessage = I18n.t("filemanager.folder_cannot_be_moved");
      return this.checkAuthorizedForMove(context.start, blockMessage);
    });

    this.filemanager.attachEvent("onBeforeDrop", (context, event) => {
      if (!context.target) {
        // Just dragging inside the same folder, does nothing but lets not prevent it.
        return true;
      }

      let item = this.filemanager.getItem(context.target.toString());
      if (item && item.type != "folder") {
        return false; // Don't allow dropping on a file
      }
    });

    this.filemanager.download = id => {
      var item = this.filemanager.getItem(id);
      if (item.type == "folder") {
        var url = `/groups/${this.groupIdValue}/attachments/zips`;
        // this no need mobile disposition, it is always attachment
        webix.send(url, { type: item.type, parent_id: id }, "GET");
      } else {
        var url = "/attachments/" + item.id + "/download";
        if (this.mobile) {
          webix.send(url, { disposition: "inline" }, "GET");
        } else {
          webix.send(url, { disposition: "inline" }, "GET", "_blank");
        }
      }
    };

    this.filemanager.showDetails = async (id, e) => {
      // This function should return a promise
      this.detailsWindow.show();
      this.uploadInfoWindow.hide();
      this.handleResize();
    };

    this.filemanager.createFolder = (id, e) => {
      if (this.actions) {
        this.actions.hide();
      }
      var url = this.filemanager.config.handlers.create.source;
      webix.ajax().post(url, { action: "create", target: id }, (text, xml, xhr) => {
        this.addFolder(JSON.parse(text), id);
        this.filemanager.showDetails();
      });
    };

    this.filemanager.chatWithAi = (id, e) => {
      post(`/ai_chats`, {
        responseKind: "turbo-stream",
        body: JSON.stringify({
          ai_chat: {
            ai_messages_attributes: [{ attachment_id: id }]
          }
        })
      });
    };
  }

  showUploadInfo() {
    this.detailsWindow.hide();
    this.uploadInfoWindow.show();
    this.handleResize();
  }

  checkAuthorizedForMove(id, blockMessage) {
    var item = this.filemanager.getItem(id);
    if (item.fixed === true || this.readonlyValue) {
      webix.message(blockMessage);
      return false;
    }
  }

  performDelete(id) {
    this.confirmed = true;
    this.filemanager.deleteFile(id);
    if (this.detailsWindow) {
      this.detailsWindow.hide();
    }
    if (!this.mobile) {
      this.resizeFileManager();
    }
    this.confirmed = false;
    return true;
  }

  addFolder(data, parentId) {
    this.filemanager.add(data, 0, parentId);
    this.filemanager.refreshCursor();
    var folder = this.filemanager.getItem(data.id);
    this.getItemInfo(folder);
  }

  attachLoadingListener() {
    this.filemanager.attachEvent("onAfterLoad", this.handleLoadingCompleted.bind(this));

    // Remove loading error handling as it causes problems when navigating away from the page
    webix.detachEvent("onLoadError");
  }

  handleLoadingCompleted() {
    this.sortFilesAscending();
    this.decorateFolderNamesWithCount();
    // Restore history from hash
    if (this.initialPath && this.expanded) {
      this.filemanager.setPath(this.initialPath);
    }

    if (this.expanded) {
      this.constructFileManagerTour();
    }
  }

  sortFilesAscending() {
    this.filemanager.data.sort("value", "asc");
    this.filemanager.$$("table").sort("value", "asc");
    this.filemanager.$$("files").sort("value", "asc");
  }

  attachResizeListener() {
    webix.event(window, "resize", this.handleResize.bind(this));
  }

  loadFiles() {
    return this.filemanager.load(this.filesUrlValue);
  }

  disconnect() {
    // Important for clean up and preventing memory leaks
    this.filemanager.destructor();
    this.restrictableAccessForm.destructor();
    this.detailsWindow.destructor();
    this.uploadInfoWindow.destructor();
    webix.html.removeStyle("default");
  }

  get mobile() {
    return this.layoutValue == "mobile";
  }

  get docked() {
    return this.layoutValue == "docked";
  }

  get expanded() {
    return this.layoutValue == "expanded";
  }

  get mode() {
    if (this.mobile) {
      return "files";
    } else if (this.docked) {
      return "files";
    } else {
      return "table";
    }
  }

  get templateNameConfig() {
    if (this.mobile) {
      return {
        templateName: (object, common) => {
          if (!common) {
            // This is the case for path (it gets called without common)
            var countAppendix = object.childCount ? ` (${object.childCount})` : "";
            return `${object.value}${countAppendix}`;
          } else {
            // These are the other cases (here we add it in the template itself not the name for mobile)
            // We needed to style the count with a badge
            return object.value;
          }
        }
      };
    } else {
      return {
        templateName: object => {
          var countAppendix = object.childCount ? ` (${object.childCount})` : "";
          return `${object.value}${countAppendix}`;
        }
      };
    }
  }

  get filemanagerConfig() {
    return {
      view: "filemanager",
      id: "filemanager",
      container: this.contentTarget,
      animate: {
        type: "flip",
        subtype: "vertical"
      },
      // need to make readonly function of user right
      compact: true,
      mode: this.mode,
      // We need to disable history, it hooks into the back button and this plays bad with Turbo
      disabledHistory: true,
      menuFilter: this.menuFilter.bind(this),
      templateCreate: this.templateCreate.bind(this),
      ...this.templateNameConfig,
      on: {
        onViewInit: this.handleViewInit.bind(this),
        onSuccessResponse: this.handleSuccessResponse.bind(this)
      },
      handlers: this.handlerUrls
    };
  }

  // Upload info window
  constructUploadInfoWindow() {
    this.uploadInfoWindow = webix.ui(this.uploadInfoWindowConfig);
    let uploadInfoForm = webix.$$("upload-info-form");

    this.uploadInfoWindow.attachEvent("onHide", () => {
      this.element.classList.remove("filemanager__container--window-open");
      this.filemanager.resize();
      this.handleResize();
    });

    this.uploadInfoWindow.attachEvent("onShow", () => {
      this.element.classList.add("filemanager__container--window-open");
      this.filemanager.resize();
      this.handleResize();
    });

    webix.html.addCss(this.uploadInfoWindow.getNode(), "filemanager__upload-info");
    uploadInfoForm.adjust();

    if (!this.readonlyValue) {
      uploadInfoForm.elements["access"].attachEvent("onChange", newValue => {
        this.membersShowHide(newValue, uploadInfoForm);
      });
    }
  }

  // Details window
  constructDetailsWindow() {
    this.detailsWindow = webix.ui(this.detailsWindowConfig);

    this.detailsWindow.attachEvent("onHide", () => {
      this.element.classList.remove("filemanager__container--window-open");
      this.filemanager.resize();
      this.handleResize();
    });

    this.detailsWindow.attachEvent("onShow", () => {
      this.element.classList.add("filemanager__container--window-open");
      this.filemanager.resize();
      this.handleResize();
    });

    webix.html.addCss(this.detailsWindow.getNode(), "filemanager__details");

    this.restrictableAccessForm = webix.$$("restrictable-access-form");

    this.restrictableAccessForm.adjust();

    this.restrictableAccessForm.elements["access"].attachEvent("onChange", (newv, oldv) => {
      this.membersShowHide(newv, this.restrictableAccessForm);
    });
    webix.extend(this.restrictableAccessForm, webix.ProgressBar);
  }

  membersShowHide(val, form) {
    if (val == "private") {
      form.elements["members"].setValue(this.currentMemberIdValue);
      form.elements["members"].show();
    } else {
      form.elements["members"].hide();
    }
  }

  get accessOptionsForSelect() {
    return [
      { id: "private", value: I18n.t("filemanager.private") },
      { id: "public", value: I18n.t("filemanager.shared") }
    ];
  }

  get membershipOptionsForSelect() {
    if (this.membersData) {
      return this.membersData;
    } else {
      return [];
    }
  }

  get uploadInfoWindowConfig() {
    return {
      view: "window",
      id: "filemanager-upload-info",
      container: this.uploadInfoTarget,
      position: this.mobile ? "bottom" : null,
      move: false,
      fullscreen: false,
      width: 0,
      borderless: !this.mobile,
      head: {
        view: "toolbar",
        borderless: this.mobile,
        cols: [
          {
            view: "template",
            template: I18n.t("filemanager.upload_info"),
            css: "text-title-3",
            borderless: true
          }
        ],
        height: 48
      },
      body: this.uploadInfoWindowBody
    };
  }

  get detailsWindowConfig() {
    return {
      view: "window",
      id: "filemanager-details",
      container: this.detailsTarget,
      position: this.mobile ? "bottom" : null,
      move: false,
      fullscreen: false,
      width: 0,
      borderless: !this.mobile,
      head: {
        view: "toolbar",
        borderless: this.mobile,
        cols: [
          {
            view: "template",
            template: I18n.t("filemanager.file_info"),
            css: "text-title-3",
            borderless: true
          },
          {
            view: "icon",
            icon: "fas fa-times fa-lg",
            css: "filemanager__details__close",
            align: "right",
            click: () => {
              this.hideDetails();
            }
          }
        ],
        height: 48
      },
      body: this.detailsWindowBody
    };
  }

  get detailsWindowCommentsCell() {
    return {
      readonly: this.readonlyValue,
      header: I18n.t("filemanager.comments"),
      body: {
        view: "comments",
        id: "comments",
        currentUser: this.currentUserIdValue,
        users: this.usersUrlValue,
        save: "saveComment->attachment/",
        mode: "chat",
        url: "loadComments->attachment/",
        moreButton: function(obj) {
          return I18n.t("chat.load_more") + " (" + obj.value + ")";
        },
        listItem: {
          templateText: function(obj) {
            return "<div class = 'comment'>" + sanitizeAll(obj.text) + "</div>";
          },
          templateAvatar: function(obj, common) {
            return "";
          }
        }
      }
    };
  }

  get inputStyle() {
    if (this.mobile) {
      return { css: "floating-input", labelPosition: "top", height: 60, inputHeight: 60 };
    } else {
      return {};
    }
  }

  get selectIconStyle() {
    if (this.mobile) {
      return { icon: "fa-regular fa-chevron-down" };
    } else {
      return {};
    }
  }

  get detailsWindowRestrictableAccessBody() {
    return {
      view: "form",
      id: "restrictable-access-form",
      width: 0,
      autoheight: true,
      elements: [
        {
          label: I18n.t("filemanager.name"),
          view: "text",
          type: "text",
          name: "value",
          readonly: this.readonlyValue,
          invalidMessage: I18n.t("js_error.cannot_be_empty"),
          ...this.inputStyle
        },
        {
          label: I18n.t("filemanager.added_by"),
          view: "text",
          type: "text",
          name: "added_by_name",
          readonly: true,
          ...this.inputStyle
        },
        {
          label: I18n.t("filemanager.access"),
          view: "richselect",
          options: this.accessOptionsForSelect,
          name: "access",
          readonly: this.readonlyValue,
          ...this.selectIconStyle,
          ...this.inputStyle
        },
        {
          label: I18n.t("filemanager.members"),
          view: "multiselect",
          options: this.membersUrlValue,
          name: "members",
          readonly: this.readonlyValue,
          invalidMessage: I18n.t("js_error.select_at_least_one"),
          ...this.selectIconStyle,
          ...this.inputStyle
        },
        {
          height: 50,
          borderless: true,
          template: `<div class="btn btn-primary w-100">${I18n.t("js.save")}</div>`,
          id: "submit-details-form",
          name: "submit",
          onClick: {
            btn: this.submitDetailsForm.bind(this)
          }
        },
        {
          height: 50,
          borderless: true,
          id: "remove-upload",
          name: "remove",
          template: `<div class="btn text-danger text-center w-100">${I18n.t(
            "filemanager.cancel"
          )}</div>`,
          onClick: {
            "text-danger": this.removeUpload.bind(this)
          }
        }
      ],
      rules: {
        value: webix.rules.isNotEmpty,
        access: webix.rules.isNotEmpty,
        members: webix.rules.isNotEmpty
      }
    };
  }

  get detailsWindowRestrictableAccessCell() {
    return {
      header: I18n.t("filemanager.details"),
      body: this.detailsWindowRestrictableAccessBody
    };
  }

  get detailsWindowBody() {
    // No Comments section for mobile for now
    if (!this.commentsEnabledValue) {
      return this.detailsWindowRestrictableAccessBody;
    } else {
      return {
        view: "tabview",
        cells: [this.detailsWindowRestrictableAccessCell, this.detailsWindowCommentsCell]
      };
    }
  }

  get uploadInfoWindowBody() {
    let formElements = [];
    let rules = {};
    if (this.readonlyValue) {
      formElements = [
        { view: "label", label: I18n.t("filemanager.public_upload_when_readonly") },
        {
          label: I18n.t("filemanager.name"),
          view: "text",
          type: "text",
          name: "value",
          invalidMessage: I18n.t("js_error.cannot_be_empty"),
          readonly: this.readonlyValue,
          ...this.inputStyle
        }
      ];
      rules = {
        value: webix.rules.isNotEmpty
      };
    } else {
      formElements = [
        {
          label: I18n.t("filemanager.access"),
          view: "richselect",
          options: this.accessOptionsForSelect,
          name: "access",
          ...this.selectIconStyle,
          ...this.inputStyle
        },
        {
          label: I18n.t("filemanager.document_name"),
          view: "text",
          type: "text",
          name: "name",
          value: "",
          ...this.inputStyle
        },
        {
          label: I18n.t("filemanager.members"),
          view: "multiselect",
          options: this.membersUrlValue,
          name: "members",
          invalidMessage: I18n.t("js_error.select_at_least_one"),
          ...this.selectIconStyle,
          ...this.inputStyle
        }
      ];
      rules = {
        value: webix.rules.isNotEmpty,
        access: webix.rules.isNotEmpty,
        members: webix.rules.isNotEmpty
      };
    }

    return {
      view: "form",
      id: "upload-info-form",
      width: 0,
      autoheight: true,
      elements: [
        ...formElements,
        {
          height: 50,
          borderless: true,
          template: `<div class="btn btn-primary w-100">${I18n.t("js.upload")}</div>`,
          id: "submit-upload-info-form",
          name: "submit",
          onClick: {
            btn: this.submitUploadInfoForm.bind(this)
          }
        },
        {
          height: 50,
          borderless: true,
          id: "cancel-upload",
          name: "cancel",
          template: `<div class="btn text-danger text-center w-100">${I18n.t(
            "filemanager.cancel"
          )}</div>`,
          onClick: {
            btn: this.cancelUpload.bind(this)
          }
        }
      ],
      rules: rules
    };
  }

  updateUnreadFilesCount() {
    if (this.hasUnreadFilesCountTarget && this.unreadFilesCountValue > 0) {
      this.unreadFilesCountTarget.classList.add("active");
      this.unreadFilesCountTarget.innerHTML = this.unreadFilesCountValue;
    }
  }

  handleFileUpload(file, response) {
    // updates files in view
    if (response.message) {
      this.displayUploadErrorMessage(response);
      this.filemanager.remove(response.id);
    } else {
      if (response.parent_id) {
        webix.message({ type: "success", text: I18n.t("filemanager.antivirus_running") });
        this.filemanager.remove(response.id);
        this.filemanager.add(response, 0, response.parent_id);
      }
    }

    this.filemanager.hideProgress();
    this.filemanager.refreshCursor();
  }

  handleFileUploadError(response) {
    var fileErrorMessage;
    if (response.xhr && response.xhr.status === 413) {
      fileErrorMessage = I18n.t("js_error.file_too_big");
    } else if (response.xhr && response.xhr.response) {
      fileErrorMessage = JSON.parse(response.xhr.response).message;
    } else {
      fileErrorMessage = I18n.t("js_error.forbidden");
    }
    response["value"] = response.name;
    response["message"] = fileErrorMessage;
    this.displayUploadErrorMessage(response);
    this.filemanager.hideProgress();
    // https://github.com/GuiSquare/paxfamilia/issues/10346
    // This has side-effect of removing response which is required for error display. So this should remain as last line.
    this.internalUploader.files.data.remove(response.id);
  }

  handleUploadComplete(response) {
    // Do not try to send the same files twice! That's why we need to clear the uploader. (Upon uploading a second after the first)
    this.filemanager.hideProgress();
    this.internalUploader.files.data.clearAll();
    this.decorateFolderNamesWithCount();
    if (!response.message) {
      var item = this.filemanager.getItem(response.id);
      this.updateItemRow(item, response);
      setTimeout(() => {
        // hacky to ensure that the file is the one visible in the form
        // and not some folder because of onFolderSelect being called
        //
        this.filemanager.showProgress({
          type: "icon",
          hide: false
        });
        this.getItemInfo(item);
        this.filemanager.hideProgress();
      }, 1000);
    }
  }

  submitUploadInfoForm() {
    var form = $$("upload-info-form");
    if (form.validate()) {
      var folderTargetId = this.internalUploader.config.formData.target;
      var files = this.internalUploader.files;
      var numberOfFiles = files.data.count();
      files.data.each(file => {
        file.name = file.name.replace(/'/g, "_");
        var formValues = form.getValues();
        file.formData = {
          id: file.id,
          parent_id: folderTargetId,
          access: formValues.access,
          name: numberOfFiles > 1 ? file.name : formValues.name,
          members: formValues.members,
          value: file.name
        };

        this.internalUploader.send(file.id);
      });
      this.uploadInfoWindow.hide();

      this.filemanager.showProgress({
        type: "top",
        hide: false,
        delay: 3000
      });
    }
  }

  displayUploadErrorMessage(response) {
    webix.message({
      type: "error",
      text: response.value + " " + I18n.t("js.could_not_be_saved") + " (" + response.message + ")",
      expire: 3000
    });
  }

  handleResize() {
    var width =
      window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    this.filemanager.resize();
    this.detailsWindow.define("height", this.detailsTarget.clientHeight);
    this.detailsWindow.define("width", this.detailsTarget.clientWidth);
    this.detailsWindow.adjust();

    this.uploadInfoWindow.define("height", this.uploadInfoTarget.clientHeight);
    this.uploadInfoWindow.define("width", this.uploadInfoTarget.clientWidth);
    this.uploadInfoWindow.adjust();
  }

  setViewType(width) {
    if (this.mobile) {
      return;
    }

    if (width != "small") {
      this.filemanager.$$("menuSpacer").show();
      this.filemanager.queryView({ innerType: "folders" }).show();
      this.filemanager.queryView({ innerType: "resizer" }).show();
      this.filemanager.$$("modes").setValue("table");
      this.filemanager.$$("table").show();
      this.filemanager.$$("modes").show();
    } else {
      if (!this.mobile) {
        this.filemanager.hideTree();
      }
    }
  }

  submitDetailsForm() {
    var form = $$("restrictable-access-form");
    if (form.validate()) {
      var values = form.getValues();
      var item = this.filemanager.getItem(values.id);
      webix.ajax().post(this.handlerUrls["update_details"], values, {
        error: (text, data, XmlHttpRequest) => {
          webix.message({ type: "error", text: JSON.parse(text)[0].message, expire: -1 });
        },
        success: (text, data, XmlHttpRequest) => {
          this.updateItemRow(item, JSON.parse(text));
          webix.message({ type: "success", text: I18n.t("filemanager.save_successful") });
          this.decorateFolderNamesWithCount();
          this.hideDetails();
        }
      });
    }
  }

  cancelUpload() {
    this.internalUploader.files.data.clearAll();
    this.decorateFolderNamesWithCount();
    this.uploadInfoWindow.hide();
  }

  removeUpload() {
    this.filemanager.confirmDelete(this.currentAttachment);
  }

  hideDetails() {
    this.detailsWindow.hide();
    this.handleResize();
  }

  handleViewInit(name, config) {
    if (name === "bodyLayout") {
      config.cols[1].innerType = "folders";
      config.cols[2].innerType = "resizer";
    }
    if (name === "tree") {
      config.template = (object, common) => {
        var countAppendix = object.childCount ? ` (${object.childCount})` : "";
        return `${common.icon(object)} ${common.folder(object)} <span>${
          object.value
        }${countAppendix}</span>`;
      };
    }
    if (this.mobile) {
      if (name == "path") {
        config.css = "webix_wrapping_path";
      }
    }
    if (name === "table") {
      this.addExtraTableColumns(config);
    }
    if (name === "actions") {
      this.configureActions(config);
    }
  }

  configureActions(config) {
    this.addExtraActionMenuItems(config);
    if (this.mobile) {
      config.position = "bottom";
      config.width = 0;

      const viewportHeight =
        window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      // Remove Separator
      config.data = [
        ...config.data.filter(item => {
          return item.$template != "Separator";
        })
      ];
      config.container = this.actionsTarget;
      config.type = {};
      config.type.height = 48;
      config.autoheight = true;
      config.template = function(object, common) {
        var name = object.value;
        var danger = object.id == "remove";
        var iconClass =
          object.icon.indexOf("fm-") == -1 ? object.icon : "webix_fmanager_icon " + object.icon;
        if (danger) {
          iconClass = iconClass + " color-danger";
        }
        var icon = `<span class='${iconClass}'></span>`;
        var label = `<div class='text-body-lite text-truncate'>${name}</div>`;
        return `<div class='row flex-nowrap g-0 m-2 ${
          danger ? "color-danger" : "color-main-font"
        } align-items-center'><div class='col-1 text-center'>${icon}</div><div class='col-11 text-truncate'>${label}</div></div>`;
      };
    }
  }

  addExtraTableColumns(tableConfig) {
    // an array with columns configuration
    var columns = tableConfig.columns;
    // configuration of a new column
    var accessColumn = {
      id: "access_text",
      header: I18n.t("filemanager.access"),
      fillspace: 1,
      editable: false
    };
    var addedByColumn = {
      id: "added_by_name",
      header: I18n.t("filemanager.added_by"),
      fillspace: 1,
      editable: false
    };
    webix.toArray(columns).insertAt(accessColumn, 1);
    webix.toArray(columns).insertAt(addedByColumn, 2);
  }

  addExtraActionMenuItems(config) {
    config.data[config.data.length - 1].value = I18n.t("filemanager.upload_file");
    config.data[config.data.length - 1].icon = "webix_fmanager_icon fal fa-file-upload";
    config.data.push({
      id: "createFolder",
      icon: "webix_fmanager_icon fal fa-folder-plus",
      value: I18n.t("filemanager.create_folder")
    });
    config.data.push({
      id: "uploadFolder",
      icon: "webix_fmanager_icon fal fa-upload",
      value: I18n.t("filemanager.upload_folder")
    });
    config.data.push({
      id: "showDetails",
      icon: "fm-edit",
      value: I18n.t("filemanager.show_details")
    });
    config.data.push({
      id: "chatWithAi",
      icon: "webix_fmanager_icon far fa-microchip-ai",
      value: I18n.t("filemanager.chat_with_ai")
    });
    // need to force download on mobile and preview after
    config.data.push({
      id: "download",
      icon: "webix_fmanager_icon fal fa-file-download",
      value: I18n.t("filemanager.download")
    });
  }

  resizeFileManager() {
    this.filemanager.resize();
  }

  handleSuccessResponse(request, response) {
    this.decorateFolderNamesWithCount();
    if (response.status && response.status === "error") {
      webix.message({ type: "error", text: response.message, expire: -1 });
      // return false;   to prevent further callback processing
    } else if (request.action === "move") {
      // also check if response.new_rights are more restrictive?
      var target = this.filemanager.getItem(request.target);
      var parentRights = target.access_text;
      this.detailsWindow.hide();
      this.resizeFileManager();
      webix.modalbox({
        title: I18n.t("filemanager.edit_access_right"),
        buttons: [I18n.t("filemanager.keep_rights"), I18n.t("filemanager.change_rights")],
        text: I18n.t("filemanager.could_loose_access", { folder_rights: parentRights }),
        width: this.modalWidth,
        callback: result => {
          if (result === "1") {
            for (var key in response) {
              if (!response.hasOwnProperty(key)) continue;
              var itemId = response[key].id;
              webix.ajax().post(
                this.handlerUrls["change_rights"],
                { action: "change_rights", target: request.target, id: itemId },
                {
                  error: (text, data, XmlHttpRequest) => {
                    webix.message({ type: "error", text: JSON.parse(text)[0].message, expire: -1 });
                  },
                  success: (text, data, XmlHttpRequest) => {
                    webix.message({ type: "success", text: I18n.t("filemanager.save_successful") });
                    var item = this.filemanager.getItem(itemId);
                    this.updateItemRow(item, JSON.parse(text));
                  }
                }
              );
            }
          }
        }
      });
    }
  }

  updateItemRow(item, values) {
    // for some reason need to call refreshCursor() for the view to update
    this.filemanager.refreshCursor();
    item["id"] = values.id;
    item["value"] = values.value;
    item["access_text"] = values.access_text;
    this.filemanager.refreshCursor();
    this.getItemInfo(item);
  }

  getItemInfo(item) {
    if (item && item.id && item.id != this.rootFolderIdValue) {
      this.restrictableAccessForm.disable();

      webix.html.removeCss(this.restrictableAccessForm.getNode(), "filemanager__form--disabled");
      webix.html.addCss(this.restrictableAccessForm.getNode(), "filemanager__form--disabled");
      this.currentAttachment = item.id;
      this.connectCommentChannel(item.id);
      webix.ajax().get(this.handlerUrls["item_info"], { id: item.id }, (text, data) => {
        this.restrictableAccessForm.setValues(JSON.parse(text));
        if (this.readonlyValue || item.fixed === true) {
          webix.html.removeCss(
            this.restrictableAccessForm.getNode(),
            "filemanager__form--disabled"
          );
          webix.html.addCss(this.restrictableAccessForm.getNode(), "filemanager__form--disabled");
          this.restrictableAccessForm.disable();
          webix.$$("remove-upload").hide();
          webix.$$("submit-details-form").hide();
        } else {
          this.restrictableAccessForm.enable();
          webix.html.removeCss(
            this.restrictableAccessForm.getNode(),
            "filemanager__form--disabled"
          );
          webix.$$("submit-details-form").show();
          webix.$$("remove-upload").show();
        }
      });
      if (this.commentsEnabledValue) {
        webix.$$("comments").clearAll();
        webix.$$("comments").load(webix.$$("comments").config.url);
      }
    }
  }

  connectCommentChannel(id) {
    // TODO Revise comment channel chat for Lite APP
    if (this.commentsEnabledValue) {
      if (App.attachment_chat) {
        App.attachment_chat.unsubscribe();
      }
      App.attachment_chat = App.cable.subscriptions.create(
        {
          channel: "AttachmentChatChannel",
          attachment_id: id
        },
        chatChannelCallbacks("comments")
      );
    }
  }

  decorateFolderNamesWithCount() {
    var countMap = {};
    this.filemanager.data.each(object => {
      if (object.type === "folder") {
        countMap[object.id] = 0;
      }
    });
    this.filemanager.data.each(object => {
      if (object.type != "folder") {
        if (object.$parent != 0) {
          countMap[object.$parent] = (countMap[object.$parent] || 0) + 1;
          var item = this.filemanager.getItem(object.$parent);
          while (item.$parent != 0) {
            countMap[item.$parent] = (countMap[item.$parent] || 0) + 1;
            item = this.filemanager.getItem(item.$parent);
          }
        }
      }
    });
    Object.keys(countMap).forEach(key => {
      var count = countMap[key];
      var item = this.filemanager.getItem(key);
      item.childCount = count;
      this.filemanager.updateItem(key, item);
    });
    this.filemanager.refreshCursor();
  }

  menuFilter(action) {
    //action - menu item action
    var context = this.filemanager.getMenu().getContext();
    //dataId - id of the clicked data item
    var dataId = context.id;
    var item = this.filemanager.getItem(dataId);
    let folderActions = ["upload", "uploadFolder", "create"];
    let editActions = ["edit", "cut", "remove"];
    var filterOnMobile = ["upload", "create", "uploadFolder", "cut", "paste", "createFolder"];
    var uploadOrCreateAction = folderActions.includes(action.id);
    var editAction = editActions.includes(action.id);

    if (action.id === "chatWithAi") {
      // Check if ai document chat is enabled
      if (!this.aiDocumentChatEnabledValue) {
        return false;
      }

      // Then check file specifics if we have an item
      if (item) {
        // Disable chat with AI for folders
        if (item.type === "folder") {
          return false;
        }

        // Disable chat with AI for unsupported file types
        // Bedrock supported file types from BedrockMessage::SUPPORTED_DOCUMENT_TYPES
        const supportedExtensions = [
          "pdf",
          "csv",
          "doc",
          "docx",
          "xls",
          "xlsx",
          "html",
          "txt",
          "md"
        ];
        const fileExtension = item.value
          .split(".")
          .pop()
          .toLowerCase();
        if (!supportedExtensions.includes(fileExtension)) {
          return false;
        }
      }

      // If we passed all checks, allow the chat with AI action
      return true;
    } else if (filterOnMobile.includes(action.id) && this.mobile) {
      return false;
    } else if (this.readonlyValue && action.id != "showDetails") {
      return false;
    } else if (item == undefined) {
      return true;
    } else if (item.fixed === true && editAction) {
      return false;
    } else if (item.type != "folder" && uploadOrCreateAction) {
      return false;
    } else if (item.id == this.rootFolderIdValue && action.id == "showDetails") {
      return false;
    } else {
      return true;
    }
  }

  templateCreate() {
    return {
      value: "New folder",
      type: "folder",
      date: new Date(),
      access_text: ""
    };
  }

  get baseUrl() {
    return `/groups/${this.groupIdValue}/documents`;
  }

  get handlerUrls() {
    return {
      remove: `${this.baseUrl}/remove`,
      rename: `${this.baseUrl}/rename`,
      move: `${this.baseUrl}/move`,
      create: `${this.baseUrl}/create_folder`,
      upload: `${this.baseUrl}/upload`,
      item_info: `${this.baseUrl}/item_info`,
      update_details: `${this.baseUrl}/update_details`,
      change_rights: `${this.baseUrl}/change_rights`,
      download: `${this.baseUrl}/download`,
      chat_with_ai: `/ai_chats`
    };
  }

  openFolder(attachableId) {
    if (this.filemanager) {
      var folders = this.filemanager.find(function(object) {
        return object.attachable_id === attachableId;
      });

      if (folders.length > 0) {
        this.filemanager.setPath(folders[0].id);
        if (!this.mobile) {
          this.filemanager.hideTree();
        }
      } else {
        console.warn(`Folder not found for ${attachableId}`);
      }
    }
  }

  addNode(obj) {
    var splitpath = obj.name.replace(/^\/|\/$/g, "").split("/");
    var ptr = this.tree;
    for (var i = 0; i < splitpath.length; i++) {
      var node = { name: splitpath[i], type: "folder" };
      if (i == splitpath.length - 1) {
        node.size = obj.size;
        node.type = obj.type;
        node.id = obj.id;
      }
      ptr[splitpath[i]] = ptr[splitpath[i]] || node;
      ptr[splitpath[i]].children = ptr[splitpath[i]].children || {};
      ptr = ptr[splitpath[i]].children;
    }
  }

  iterateTree(tree, parentId) {
    for (var key in tree) {
      // skip loop if the property is from prototype
      if (!tree.hasOwnProperty(key)) continue;
      var treeObject = tree[key];
      var type = treeObject.type;
      if (type === "folder") {
        this.saveFolder(parentId, treeObject);
      } else {
        this.saveFile(parentId, treeObject);
      }
    }
  }

  saveFolder(parentId, treeObject) {
    webix.ajax().post(
      this.handlerUrls["create"],
      { action: "create", target: parentId, name: treeObject.name },
      {
        error: (text, data, XmlHttpRequest) => {
          webix.message({ type: "error", text: JSON.parse(text)[0].message, expire: -1 });
        },
        success: (text, data, XmlHttpRequest) => {
          webix.message({ type: "success", text: I18n.t("filemanager.save_successful") });
          this.addFolder(JSON.parse(text), parentId);
          this.iterateTree(treeObject.children, JSON.parse(text).id);
        }
      }
    );
  }

  addFolder(data, parentId) {
    this.filemanager.add(data, 0, parentId);
    this.filemanager.refreshCursor();
    var folder = this.filemanager.getItem(data.id);

    this.getItemInfo(folder);
  }

  saveFile(parentId, treeObject) {
    let file = this.internalUploader.files.getItem(treeObject.id);
    // overiding target param doesn't work so setting parent id param instead
    console.log("treeObject");
    console.log(treeObject);
    console.log(this.internalUploader.files.data);
    this.internalUploader.files.data.each(datastorefile => {
      console.log("DSF", datastorefile);
    });
    if (file) {
      file.name = file.name.replace(/'/g, "_");
      file.formData = { parent_id: parentId, id: file.id };
      this.internalUploader.send(file.id);
    }
  }

  markOnboardingSeen() {
    $.ajax({
      url: "/user/update_settings",
      method: "patch",
      data: { user: { filemanager_onboarding_seen: true } },
      dataType: "json"
    });
  }

  markAllAsRead() {
    $.ajax({
      url: `/attachments/${this.rootFolderIdValue}/read_files`,
      method: "patch",
      success: data => {
        this.unreadFilesCountTarget.classList.remove("active");
        this.unreadFilesCountTarget.innerHTML = "";
      },
      error: function(xhr) {
        console.log(xhr);
      }
    });
  }
}
