import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    text: { type: String },
    successDelay: { type: Number, default: 1000 }
  };
  static targets = [ "button", "success" ];

  copy(event) {
    event.preventDefault()
    if (!navigator.clipboard) return

    navigator.clipboard.writeText(this.textValue);
    this.buttonTarget.classList.toggle("hidden")
    this.successTarget.classList.toggle("hidden")
    setTimeout(() => {
      this.buttonTarget.classList.toggle("hidden")
      this.successTarget.classList.toggle("hidden")
    }, this.successDelayValue);
  }
}
