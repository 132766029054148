import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";

export default class extends Controller {
  static values = {
    events: { type: Array, default: ["input"] }
  };

  initialize() {
    this.debouncedSubmit = debounce(this.submitForm.bind(this));
  }

  connect() {
    this.eventsValue.forEach((eventName) => {
      this.element.addEventListener(eventName, this.debouncedSubmit)
    })
  }

  submitForm() {
    this.element.closest("form").requestSubmit();
  }
}
