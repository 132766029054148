import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    autoSubmit: { type: Boolean, default: false }
  };

  connect() {
    $(this.element)
      .select2({
        theme: "bootstrap",
        allowClear: true,
        placeholder: this.element.getAttribute("placeholder"),
        width: "100%"
      })
      .on("select2:select", () => this.autoSubmitForm())
      .on("select2:unselect", () => this.autoSubmitForm())
      .on("select2:clear", () => this.autoSubmitForm())
  }

  autoSubmitForm() {
    if (this.autoSubmitValue) {
      this.element.closest("form").requestSubmit();
    }
  }
}
